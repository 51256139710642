<template>
    <div>
                    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li>Contact US</li>
        </ol>
        <h2>Contact US</h2>

      </div>
    </section><!-- End Breadcrumbs -->
          <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <header class="section-header horizontal-center">
          <div class="row">
            
            <p>Contact Us</p>
          </div>
        </header>

        <div class="row gy-4">
          <div class="col-lg-6">
            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>Ethio-China Street (Wollo Sefer), KT-12 Building</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+251114 401472/73</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@firstconsultet.com</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <form>
              <div class="row gy-4">
                <div class="col-md-6">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    v-model="contactusData.fullName"
                    placeholder="Your Name"
                    required
                  />
                </div>

                <div class="col-md-6">
                  <input
                    type="email"
                    class="form-control"
                    v-model="contactusData.email"
                    name="email"
                    placeholder="Your Email"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    v-model="contactusData.subject"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <textarea
                    class="form-control"
                    v-model="contactusData.comment"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <b-button @click="addComment">Send Message <b-spinner v-if="loading"  small></b-spinner></b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>
<script>
import { getDatas, getFields, postDataHeader, postFields } from "../assets/js/service";
import Vue from "vue";
export default {
   metaInfo: {
    // Children can override the title.
    title: 'ContactUs',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Contact Us"},
      {property:"og:title" ,content:"First Consult Contact Us"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/contact_us"},


      {name:"twitter:title" ,content:"First Consult Contact Us"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/contact_us"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
    data() {
        return {
                  loading:false,
      contactusData: {
        email: null,
        fullName: null,
        subject: null,
        comment: null,
      },
        }
    },
     watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
    methods: {
          routeTo(route){
      
     
      this.$router.push(route);
    },
            addComment() {
      this.loading = true;
      let token = localStorage.getItem("token");
      postDataHeader("comments/register", {email:"lyohannes@firstconsultet.com",comment:this.contactusData}, token).then((resp) => {
        this.contactusData = {
          email: null,
          fullName: null,
          subject: null,
          comment: null,
        };
         this.loading = false;
        Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
    },
}
</script>
<style>
    
</style>